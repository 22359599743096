@font-face {
    font-family: 'FiraCode';
    font-weight: normal;
    font-style: normal;
    src: url('./FiraCode-Regular.woff') format('woff');
}

@font-face {
    font-family: 'FiraCode';
    font-weight: bolder;
    font-style: normal;
    src: url('./FiraCode-Bold.woff') format('woff');
}

@font-face {
    font-family: 'FiraCode';
    font-weight: bold;
    font-style: normal;
    src: url('./FiraCode-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'FiraCode';
    font-weight: lighter;
    font-style: normal;
    src: url('./FiraCode-Light.woff') format('woff');
}

